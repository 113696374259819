export enum UsageProfileEnum {
  CONSUMER_MARKET = 'Verbrauchermarkt',
  EDUCATION = 'Bildung',
  HEALTH_BUILDINGS = 'Gesundheitsbauten',
  HOTEL = 'Hotel',
  LIVING = 'Wohnen (6 WE bis 12 WE)',
  LIVING_HEALTH = 'Wohnen für Pflegeimmobilien',
  LIVING_LARGE = 'Wohnen (mehr als 12 WE)',
  LIVING_SMALL = 'Wohnen (bis 5 WE)',
  LIVING_SMALLER = 'Wohnen (bis 2 WE)',
  LOGISTICS = 'Logistik',
  MEETING_PLACES = 'Versammlungsstätten',
  OFFICE = 'Büro',
  OFFICE_BUILDING = 'Geschäftshaus',
  PRODUCTION = 'Produktion',
  SHOPPING_CENTER = 'Shoppingcenter',
}
