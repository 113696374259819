import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

export type Severity = 'success' | 'warning' | 'error' | 'default' | undefined;

@Injectable({
  providedIn: 'root',
})
/**
 * @author Christian Sanker
 * Service to manage snack bar notifications.
 */
export class SnackbarService {
  constructor(private _snackBar: MatSnackBar) {
  }

  /**
   * Shows a message in the snack bar.
   * @param {string} message - The message to display.
   * @param {Severity} [severity] - The severity of the message (optional).
   * @param {number} [duration] - The duration of the message display (optional).
   */
  showMessage(message: string, severity?: Severity, duration?: number) {
    const config: MatSnackBarConfig = new MatSnackBarConfig();
    if (severity) config.panelClass = severity;
    config.duration = duration || 2000;
    config.horizontalPosition = 'center';
    this.openSnackbar(message, undefined, config);
  }

  /**
   * Shows an error message in the snack bar.
   * @param {string} message - The error message to display.
   * @param {number} [duration] - The duration of the error message display (optional).
   */
  showErrorMessage(message: string, duration?: number) {
    const config: MatSnackBarConfig = new MatSnackBarConfig();
    config.panelClass = 'error';
    config.duration = duration || 2000;
    config.horizontalPosition = 'center';
    this.openSnackbar(message, undefined, config);
  }

  /**
   * Opens Mat Snackbar
   * @param message
   * @param action
   * @param config
   * @private
   */
  private openSnackbar(message: string,
                       action?: string,
                       config?: MatSnackBarConfig) {
    // TODO integrate action if needed
    if (action) console.warn(action, 'TODO: integrate action');
    this._snackBar.open(message, undefined, config);
  }
}
