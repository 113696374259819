import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { ErrorStateMatcher, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DocumentData } from '@eeule/eeule-shared/src/types';
import { v4 as uuidv4 } from 'uuid';
import { ProjectService } from '../../../core/services/project.service';
import { SnackbarService } from '../../../core/services/snackbar.service';
import { StorageService, allowedDocumentExtensions } from '../../../core/services/storage.service';
import { UserService } from '../../../core/services/user.service';
import { DocumentTypeEnum } from '../../../enums/DocumentType.enum';
import { MatTooltip } from '@angular/material/tooltip';

interface UploadDocumentForm {
  id: FormControl<string | null>;
  indicatorReferences: FormArray;
  tasksIds: FormArray;
  createdDate: FormControl<number | null>;
  lastUpdatedDate: FormControl<number | null>;
  creatorId: FormControl<string | null>;
  description: FormControl<string | null>;
  format: FormControl<string | null>;
  name: FormControl<string | null>;
  type: FormControl<DocumentTypeEnum | null>;
  version: FormControl<number | null>;
  data: FormControl<File | null>;
}

export interface UploadDocumentDialogConfig extends Partial<DocumentData> {}

@Component({
  selector: 'eule-upload-document-dialog',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatTooltip,
  ],
  templateUrl: './upload-document-dialog.component.html',
  styleUrl: './upload-document-dialog.component.scss',
})
export class UploadDocumentDialogComponent {
  public allowedFileExtensions = allowedDocumentExtensions.map(ext => `.${ext}`).join(', ');
  public errorStateMatcher: ErrorStateMatcher = new ErrorStateMatcher();

  public documentForm: FormGroup<UploadDocumentForm> = this._createDocumentForm();
  public documentTypes = DocumentTypeEnum;

  constructor(
    public dialogRef: MatDialogRef<UploadDocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public documentData: UploadDocumentDialogConfig,
    private _formBuilder: FormBuilder,
    public projectService: ProjectService,
    public userService: UserService,
    private _storageService: StorageService,
    private _snackbarService: SnackbarService
  ) {
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFileSelected(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      if (!this._storageService.isDocumentUploadAllowed(file)) {
        this._snackbarService.showErrorMessage('Dateityp oder Dateigröße nicht erlaubt.');
        throw new Error('Dateityp oder Dateigröße nicht erlaubt.');
      }
      this.documentForm.get('name')!.setValue(file.name);
      const formData = new FormData();
      formData.append('thumbnail', file);
      this.documentForm.get('data')!.setValue(file);
      this.documentForm.markAllAsTouched();
    }
  }

  public closeDialog(save: boolean): void {
    if (save) {
      this.dialogRef.close({
        ...this.documentForm.getRawValue(),
        createdDate: new Date(this.documentForm.get('createdDate')!.value!).getTime() || new Date().getTime(),
        lastUpdatedDate: new Date(this.documentForm.get('lastUpdatedDate')!.value!).getTime() || new Date().getTime(),
      } as DocumentData);
    } else {
      this.dialogRef.close(undefined);
    }
  }

  private _createDocumentForm(): FormGroup {
    let newVersion: number = this.documentData?.version || 0;
    newVersion++;
    return this._formBuilder.group({
      id: [this.documentData?.id || uuidv4(), [Validators.required]], // Attention: here is a new id created if it is not an existing task
      indicatorReferences: this._formBuilder.array(this.documentData?.indicatorReferences || []),
      tasksIds: this._formBuilder.array(this.documentData?.tasksIds || []),
      createdDate: [{ value: this.documentData?.createdDate || new Date(), disabled: true }, [Validators.required]],
      lastUpdatedDate: [{ value: (this.documentData?.lastUpdatedDate && new Date(this.documentData?.lastUpdatedDate)) || new Date(), disabled: true }],
      creatorId: [{ value: this.documentData?.creatorId || this.projectService.projectUser$.value!.id, disabled: true }, [Validators.required]],
      description: [this.documentData?.description || null],
      format: [this.documentData?.format || null],
      name: [this.documentData?.name || null, [Validators.required]],
      type: [this.documentData?.type || 'DOCUMENT', [Validators.required]],
      version: [{ value: newVersion, disabled: true }, [Validators.required]],
      data: [this.documentData?.data || null, [Validators.required]],
    });
  }
}
