import { UsageProfileEnum } from '../app/enums/UsageProfile.enum';
import { IndicatorStatusEnum } from '../app/enums/IndicatorStatus.enum';
import { DgnbSubjectEnum } from '../app/enums/DgnbSubject.enum';
import { UsageProfileTypeEnum } from '../app/enums/UsageProfileTypeEnum';
import { DisciplineEnum } from '../app/enums/Discipline.enum';

export function getDgnbSubjectEnumValue(key: string | null | undefined): string {
  if (!key) return '';
  return DgnbSubjectEnum[key.toUpperCase() as keyof typeof DgnbSubjectEnum];
}

export function getUsageProfileEnumValue(key: string | null | undefined): string {
  if (!key) return '';
  return UsageProfileEnum[key as keyof typeof UsageProfileEnum];
}

export function getUsageProfileTypeEnumValue(key: string | null | undefined): string {
  if (!key) return '';
  return UsageProfileTypeEnum[key as keyof typeof UsageProfileTypeEnum];
}

export function getIndicatorStatusEnumValue(key: string | null | undefined): string {
  if (!key) return '';
  return IndicatorStatusEnum[key as keyof typeof IndicatorStatusEnum];
}

export function getDisciplineEnumValue(key: string | null | undefined): string {
  if (!key) return '';
  return DisciplineEnum[key as keyof typeof DisciplineEnum];
}
