import {Injectable} from '@angular/core';
import {UsageProfile} from "@eeule/eeule-shared/src";
import {addDoc, collection, deleteDoc, doc, DocumentReference, serverTimestamp, updateDoc} from "firebase/firestore";
import {from, Observable} from "rxjs";
import { FirebaseService } from '../firebase.service';
import {
  CollectionQueryResponse,
  DocumentQueryResponse,
  OrderByCondition,
  QueryCondition,
} from '../../../types/firebase-types';

/**
 * @Author Christian Sanker
 * Service for managing usage profiles in Firebase.
 * Provides methods for adding, retrieving, updating, and deleting usage profiles.
 */
@Injectable({
  providedIn: 'root'
})
export class UsageProfileService {

  constructor(private _firebaseService: FirebaseService) {
  }

  /**
   * Adds a new usage profile to the Firestore collection.
   * @param data Partial data of the usage profile to be added.
   * @returns An observable emitting the document reference of the newly added usage profile.
   */
  addUsageProfile(data: Partial<UsageProfile>): Observable<DocumentReference> {
    const collectionRef = collection(this._firebaseService.firestore,
      `usageProfiles`);
    return from(addDoc(collectionRef, {
      ...data,
      createTime: serverTimestamp(),
      updateTime: serverTimestamp()
    }))
  }

  /**
   * Retrieves a usage profile from Firestore based on the provided ID.
   * @param id The ID of the usage profile to retrieve.
   * @returns An observable emitting the document query response of the retrieved usage profile.
   */
  getUsageProfile(id: string): Observable<DocumentQueryResponse<UsageProfile>> {
    return this._firebaseService.getDocumentData<UsageProfile>(
      `usageProfiles`, id)
  }

  /**
   * Retrieves multiple usage profiles from Firestore based on optional query conditions and order by conditions.
   * @param queryConditions Optional array of query conditions to filter the usage profiles.
   * @param orderByCondition Optional order by condition to sort the usage profiles.
   * @returns An observable emitting the collection query response of the retrieved usage profiles.
   */
  getUsageProfiles(queryConditions?: QueryCondition[] | null,
                   orderByCondition?: OrderByCondition | null): Observable<CollectionQueryResponse<UsageProfile>> {
    const orderByConditions: OrderByCondition[] | null = orderByCondition ? [orderByCondition] : null;
    return this._firebaseService.getCollectionData<UsageProfile>(
      `usageProfiles`,
      queryConditions,
      orderByConditions);
  }

  /**
   * Updates an existing usage profile in Firestore.
   * @param id The ID of the usage profile to update.
   * @param data Partial data of the usage profile to update.
   * @returns An observable emitting void upon successful update.
   */
  updateUsageProfile(id: string, data: Partial<UsageProfile>): Observable<void> {
    const docRef = doc(this._firebaseService.firestore,
      `usageProfiles`, id);
    return from(updateDoc(docRef, {
      ...data,
      updateTime: serverTimestamp(),
    }))
  }

  /**
   * Deletes an existing usage profile from Firestore.
   * @param id The ID of the usage profile to delete.
   * @returns An observable emitting void upon successful deletion.
   */
  deleteUsageProfile(id: string): Observable<void> {
    const docRef = doc(this._firebaseService.firestore,
      `usageProfiles`, id);
    return from(deleteDoc(docRef));
  }
}
