<div mat-dialog-title class="dialog-title flexbox align-center">
  <div class="title">Dokument Hochladen</div>
  <div class="flex-grow-1 flexbox justify-end">
    <button mat-icon-button class="close-button smaller-button"
            [matTooltip]="'Dialog schließen'"
            (click)="dialogRef.close(false)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content [formGroup]="documentForm" class="container">
  <input type="file" [accept]="allowedFileExtensions" class="file-input" (change)="onFileSelected($event)"
         #fileUpload />

  <div class="row">
    <mat-form-field class="w-100-percent">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" [errorStateMatcher]="errorStateMatcher" />
      @if (documentForm.get('name')?.hasError('required')) {
        <mat-error>Bitte geben Sie einen Dateinamen an</mat-error>
      }
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field class="w-100-percent">
      <mat-label>Beschreibung</mat-label>
      <textarea
        matInput
        formControlName="description"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="4"
        cdkAutosizeMaxRows="8"
      ></textarea>
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field class="w-100-percent">
      <mat-label>Typ</mat-label>
      <mat-select formControlName="type" [errorStateMatcher]="errorStateMatcher">
        @for (type of documentTypes | keyvalue; track type) {
          <mat-option [value]="type.key">{{ type.value }}</mat-option>
        }
      </mat-select>
      @if (documentForm.get('type')?.hasError('required')) {
        <mat-error>Bitte Dateitypen auswählen</mat-error>
      }
    </mat-form-field>
    <mat-form-field class="w-100-percent">
      <mat-label>Ersteller</mat-label>
      <mat-select formControlName="creatorId">
        @for (user of projectService.projectUsers$ | async; track user) {
          <mat-option [value]="user.id">{{ user.firstName }} {{ user.lastName }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field class="w-100-percent">
      <mat-label>Version</mat-label>
      <input matInput formControlName="version" />
    </mat-form-field>
    <mat-form-field class="w-100-percent">
      <mat-label>Datum</mat-label>
      <input matInput formControlName="lastUpdatedDate" [matDatepicker]="dp" />
      <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
      <mat-datepicker #dp></mat-datepicker>
    </mat-form-field>
  </div>

  <div>
    <p>@if (documentForm.get('data')!.value && !documentForm.get('name')!.value) {
      Name vergeben
    } @else {
      {{ documentForm.get('name')!.value }}
    }</p>

    <div class="upload-action-wrapper flexbox align-center smaller-gap">
      <button mat-raised-button color="accent" class="flexbox no-shadow choose-file-button"
              (click)="fileUpload.click()">
        <mat-icon>attach_file</mat-icon>
        <div>Datei auswählen</div>
      </button>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="flexbox justify-spaced align-center">
  <div class="file-size-info secondary-text flex-grow-1">
    Es sind Dateigrößen von maximal 50 MB zugelassen.
  </div>
  <div class="buttons">
    <button mat-flat-button cdkFocusInitial [disabled]="!fileUpload.value || documentForm.invalid" color="primary"
            (click)="closeDialog(true)">Speichern
    </button>
    <button mat-stroked-button (click)="closeDialog(false)">Abbrechen</button>
  </div>
</mat-dialog-actions>
