<div mat-dialog-title class="dialog-title flexbox align-center smaller-spacing">
  <div class="title">Anhang zuweisen</div>
  <div class=" flex-grow-1 flexbox justify-end">
    <button mat-icon-button class="close-button smaller-button"
            [matTooltip]="'Dialog schließen'"
            (click)="dialogRef.close(this.attachmentListIsDirty)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content class="dialog-content flexbox direction-column smaller-gap">
  <eule-search-bar [search]="(search$ | async) || ''"
                   (searchChange)="onAttachmentSearch($event)"
                   class="w-100-percent attachment-list-search" />
  <div class="attachment-list flexbox direction-column xs-gap">
    <div class="attachment-list-label strong">
      Vorhandene Anhänge
    </div>
    <div class="attachments secondary-background default-border flexbox direction-column small-gap">
      @if (filteredAttachments) {
        @for (attachment of filteredAttachments; track attachment) {
          <div class="attachment flexbox small-gap cursor-pointer align-center"
               [matTooltip]="attachment.description || ''">
            <mat-checkbox [checked]="attachment.connected"
                          (change)="onConnectTaskAttachment($event, attachment)" />
            <div class="attachment-name cursor-pointer" (keydown)="onAttachmentKeyDown($event, attachment.id)"
                 tabindex="0"
                 role="button"
                 (click)="onOpenAttachment(attachment.id)">
              {{ attachment.name }}
            </div>
          </div>
        }
      }
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="dialog-actions">
  @if (isLoading || isUpdating) {
    <div class="flexbox align-center small-gap loading-indicator">
      <mat-spinner diameter="25" />
      <div class="secondary-text spinner-text">Daten werden verarbeitet</div>
    </div>
  } @else {
    <button mat-flat-button color="accent"
            class="flexbox xs-gap align-center"
            (click)="onNewDocument()"
            type="button">
      <mat-icon>add</mat-icon>
      <div class="button-text">Hochladen</div>
    </button>
    @if (attachmentListIsDirty) {
      <button mat-flat-button type="button" color="primary"
              (click)="dialogRef.close(connectedAttachments)">
        Ok
      </button>
    } @else {
      <button mat-stroked-button type="button"
              (click)="dialogRef.close(null)">
        Schließen
      </button>
    }
  }
</mat-dialog-actions>
