import { Component, OnDestroy } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'eule-base',
  standalone: true,
  imports: [],
  templateUrl: './base.component.html',
  styleUrl: './base.component.scss',
})
export abstract class BaseComponent implements OnDestroy {
  public stop$: ReplaySubject<boolean> = new ReplaySubject(1);
  ngOnDestroy() {
    this.stop$.next(true);
    this.stop$.complete();
  }
}
