import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const germanNumberExp: RegExp = new RegExp("^-?(\\d{1,3}(\\.\\d{3})*|\\d+)(,\\d+)?$");

export const oneToOneHundredExp: RegExp = new RegExp("^(100|[1-9][0-9]?)$");

export const germanNumberWithLeadingPlusAndHyphenInBetween: RegExp
  = new RegExp("^[+]?(\\d+(,\\d*)?)(-([+]?(\\d+(,\\d*)?)))?$");

// export function passwordMatchValidator(formGroup: FormGroup): { [key: string]: boolean } | null {
//   const password = formGroup.get('password')?.value;
//   const confirmPassword = formGroup.get('confirmPassword')?.value;
//   return password && confirmPassword && password === confirmPassword ? null : { mismatch: true };
// }

export function passwordMisMatchValidator(passwordToMatchControlName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const password = control.value;
    const passwordToMatch = control.parent?.get(passwordToMatchControlName);

    if (!password || !passwordToMatch?.value) {
      return null;
    }

    if (!passwordToMatch?.value) {
      return { mismatch: true };
    }

    return passwordToMatch.value !== password ? { mismatch: true } : null;
  };
}

