import { Component } from '@angular/core';

export interface ViewDocumentDialogConfig {
  data: Document;
}

@Component({
  selector: 'eule-view-document-dialog',
  standalone: true,
  imports: [],
  templateUrl: './view-document-dialog.component.html',
  styleUrl: './view-document-dialog.component.scss',
})
export class ViewDocumentDialogComponent {}
