import { DgnbSubjectEnum } from '../app/enums/DgnbSubject.enum';

export function mapAndTranslateIndicatorSubjects(subject: DgnbSubjectEnum) {
  switch (subject) {
    case DgnbSubjectEnum.ENV: return 'Ökologische Qualität';
    case DgnbSubjectEnum.ECO: return 'Ökonomische Qualität';
    case DgnbSubjectEnum.SOC: return 'Soziokulturelle Qualität';
    case DgnbSubjectEnum.TEC: return 'Technische Qualität';
    case DgnbSubjectEnum.PRO: return 'Prozess Qualität';
    case DgnbSubjectEnum.SITE: return 'Standortqualität';
    default: return '';
  }
}
