import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[euleAutofocus]',
  standalone: true,
})
export class EuleAutofocusDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef) {}

  public ngAfterViewInit() {
    setTimeout(() => {
      this.elementRef.nativeElement.focus();
    }, 200);
  }
}
