import {Component, Inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from "@angular/material/dialog";
import {  MatButtonModule } from '@angular/material/button';

export type ConfirmDialogData = {
  /**
   * For localization
   * TODO: remove or add localization
   */
  titleLangKey?: string;

  /**
   * Dynamic title
   */
  dynamicTitle?: string

  /**
   * For localization
   * TODO: remove or add localization
   * content key that will be translated from i18n file
   */
  contentLangKey?: string;

  /**
   * Dynamic content
   */
  dynamicContent?: string
}

@Component({
  selector: 'eule-confirm-dialog',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatButtonModule,
  ],
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.scss'
})
export class ConfirmDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {
  }
}
