import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { BaseComponent } from '../../../core/components/base/base.component';
import { ProjectService } from '../../../core/services/project.service';
import { StorageService } from '../../../core/services/storage.service';
import {
  ViewDocumentDialogComponent,
  ViewDocumentDialogConfig,
} from '../view-document-dialog/view-document-dialog.component';
import { DocumentData, Project } from '@eeule/eeule-shared/src/types';
import { MatIconModule } from '@angular/material/icon';
import { handleBasicError } from '../../../../util/error.helper';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'eule-document-tile',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatDialogModule, MatProgressSpinnerModule, MatIconModule, MatTooltipModule],
  templateUrl: './document-tile.component.html',
  styleUrl: './document-tile.component.scss',
})
export class DocumentTileComponent extends BaseComponent implements OnChanges {
  @Input({ required: true }) documentId!: string;
  @Input() temporary?: boolean;
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public documentData: DocumentData | null = null;

  public constructor(
    private _taskDialog: MatDialog,
    private _projectService: ProjectService,
    private _storageService: StorageService
  ) {
    super();
  }

  ngOnChanges() {
    if (this.documentId) {
      this.isLoading$.next(true);
      this._storageService.getProjectDocumentData(this._projectService.project$.value!.id, this.documentId).subscribe((_documentData: DocumentData) => {
        this.isLoading$.next(false);
        this.documentData = _documentData;
      });
    }
  }

  async onAttachmentKeyDown(event: KeyboardEvent, id: string) {
    if (event.key === 'Enter' || event.key === ' ') {
      await this.onOpenAttachment(id);
      event.preventDefault();
    }
  }

  async onOpenAttachment(id: string) {
    const project: Project | null = this._projectService.project$.value;
    if (!project) {
      return handleBasicError('Error while retrieving current project');
    }
    const downloadUrl: string = await lastValueFrom(this._storageService.getProjectDocumentDownloadUrl(project.id, id));
    window.open(downloadUrl, '_blank');
  }

  public openDocument() {
    if (this.isLoading$.value) return;
    const dialogRef = this._taskDialog.open<ViewDocumentDialogComponent, ViewDocumentDialogConfig, Document | null>(ViewDocumentDialogComponent, {
      width: '90vw',
      maxWidth: '90vw',
      height: '90vh',
      data: null,
    });

    dialogRef.afterClosed().subscribe((result: Document | null | undefined) => {
      console.info('afterClosed', result);
    });
  }
}
