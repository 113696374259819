<div class="document-tile flexbox align-center justify-center">
  @if (isLoading$ | async) {
    <div class="spinner flexbox justify-center">
      <mat-spinner diameter="25"></mat-spinner>
    </div>
  } @else if (documentData?.id) {
    <div class="attachment flexbox direction-column"
         [ngClass]="{
         'temporary': temporary,
         'default-border-color': temporary,
         'quaternary-background' : temporary
         }"
         [matTooltip]="temporary
          ? 'Das Dokument wird beim Speichern verknüpft. ' + documentData?.name || ''
          : documentData?.name || ''"
         (keydown)="onAttachmentKeyDown($event, documentData!.id)"
         tabindex="0"
         role="button"
         (click)="onOpenAttachment(documentData!.id)">
      <div class="tile-content-wrapper">
        <mat-icon class="attachment-icon">
          description
        </mat-icon>
        <div class="attachment-name">
          {{ documentData?.name || '' }}
        </div>
      </div>
    </div>
  }
</div>
