/**
 * Handles basic errors by throwing an appropriate error.
 *
 * @param {unknown} [error] - The error to handle. It can be of any type.
 * @throws {Error} Throws an error with an appropriate message.
 */
export function handleBasicError(error?: unknown) {
  if (typeof error === 'string') {
    throw new Error(error);
  }
  if ((error as Error).message) {
    throw new Error((error as Error).message);
  }
  if (error) {
    throw (error);
  }

  throw ('an error occurred');
}
