<mat-card class="custom-card row-hover pointer secondary-background-alt">
  <mat-card-content class="table-card-content">
    <table mat-table [dataSource]="dataSource" matSort class="differ-rows indicator-table">
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Typ</th>
        <td mat-cell *matCellDef="let row" class="type-col">{{ row.type }}</td>
      </ng-container>

      <ng-container matColumnDef="scenarioName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Szenario</th>
        <td mat-cell *matCellDef="let row">{{ row.scenarioName }}</td>
      </ng-container>

      <ng-container matColumnDef="subject">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Themenfeld</th>
        <td mat-cell *matCellDef="let row">{{ row.subject }}</td>
      </ng-container>

      <ng-container matColumnDef="indicatorInfo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Indikator</th>
        <td mat-cell *matCellDef="let row">{{ row.indicatorInfo }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="indicator-table-row"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="openIndicator(row)"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row no-data" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          <div class="small-padding">
            Kein Indikator ist mit dieser Aufgabe verknüpft
          </div>
        </td>
      </tr>
    </table>

    <mat-paginator
      [pageSizeOptions]="[5, 10, 25, 100]"
      [pageSize]="5"
      aria-label="Seite auswählen"
      class="indicator-table-pagination"
    ></mat-paginator>
  </mat-card-content>
</mat-card>
