import { Injectable } from '@angular/core';
import { FirebaseApp, initializeApp } from 'firebase/app';
import {
  collection,
  CollectionReference,
  doc,
  DocumentData,
  DocumentReference,
  Firestore,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  Query,
  QueryConstraint,
  where,
} from 'firebase/firestore';
import { Functions, getFunctions } from 'firebase/functions';
import { environment } from '../../../environments/environment';
import { catchError, from, map, Observable, of, startWith } from 'rxjs';
import { CollectionQueryResponse, DocumentQueryResponse, OrderByCondition, QueryCondition } from '../../types/firebase-types';
import { generateQueryConstraints } from '../../../util/firebase.helper';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  public firebaseApp: FirebaseApp;
  public functions: Functions;
  public firestore: Firestore;

  constructor() {
    this.firebaseApp = this.initialize();
    //with no parameter, using default database
    this.firestore = getFirestore(this.firebaseApp);

    // FIXME: fix
    this.functions = getFunctions(this.firebaseApp, 'europe-west3');
  }

  public initialize() {
    return initializeApp(environment.firebaseConfig);
  }

  /**
   * Retrieves data from a document in Firestore.
   * @param {string} collectionPath - The path to the collection.
   * @param {string} id - The id of the document.
   * @param {boolean} skipIsFetching - if true startWith will not trigger emit.
   * @returns {Observable<DocumentQueryResponse>} An observable of the retrieved data.
   */
  public getDocumentData<T>(collectionPath: string, id: string, skipIsFetching?: boolean): Observable<DocumentQueryResponse<T>> {
    try {
      const docRef: DocumentReference<DocumentData, DocumentData> = doc(this.firestore, collectionPath, id);
      let documentQuery$: Observable<DocumentQueryResponse<T>> = from(getDoc(docRef)).pipe(
        map(snapshot => {
          if (!snapshot.exists()) throw new Error('Document does not exist!');
          return {
            data: {
              ...(snapshot.data() as T),
              id: snapshot.id,
              metadata: snapshot.metadata,
            },
            doc: snapshot,
            documentPath: docRef.path,
            isFetching: false,
          };
        }),
        catchError(error => {
          console.error(error);
          return of({ isFetching: false, hasError: true, error });
        })
      );

      if (!skipIsFetching) {
        documentQuery$ = documentQuery$.pipe(startWith({ isFetching: true }));
      }

      return documentQuery$;
    } catch (error) {
      // Catch synchronous errors
      console.error(error);
      // Return an observable that emits an error state
      return of({ isFetching: false, hasError: true, error });
    }
  }

  /**
   * Retrieves data from a collection in Firestore.
   * @param {string} collectionPath - The path to the collection.
   * @param {QueryCondition[]} [queryConditions] - The query conditions.
   * @param {OrderByCondition[]} [orderByConditions] - The orderBy conditions.
   * @param {boolean} [skipIsFetching] - if true startWith will not trigger emit
   * @returns {Observable<CollectionQueryResponse>} An observable of the retrieved data.
   */
  public getCollectionData<T>(
    collectionPath: string,
    queryConditions?: QueryCondition[] | null,
    orderByConditions?: OrderByCondition[] | null,
    skipIsFetching?: boolean
  ): Observable<CollectionQueryResponse<T>> {
    try {
      const collectionRef: CollectionReference<DocumentData, DocumentData> = collection(this.firestore, collectionPath);
      // Prepare query constraints from conditions
      const queryConstraints: QueryConstraint[] = generateQueryConstraints(queryConditions, orderByConditions);
      const q: Query<DocumentData> = query(collectionRef, ...queryConstraints);
      let collectionQuery$: Observable<CollectionQueryResponse<T>> = from(getDocs(q)).pipe(
        map(snapshot => {
          return {
            data: snapshot.docs.map(doc => ({
              ...(doc.data() as T),
              id: doc.id,
              metadata: doc.metadata,
              exists: doc.exists(),
            })),
            docs: snapshot.docs,
            collectionPath: collectionRef.path,
            isFetching: false,
          };
        }),
        catchError(error => {
          console.error(error);
          return of({ isFetching: false, hasError: true, error });
        })
      );

      if (!skipIsFetching) {
        collectionQuery$ = collectionQuery$.pipe(startWith({ isFetching: true }));
      }
      return collectionQuery$;
    } catch (error) {
      // Catches synchronous errors (maybe unnecessary)
      console.error(error);
      // Returns an observable that matches the expected result
      return of({ isFetching: false, hasError: true, error });
    }
  }
}
