export const environment = {
  production: false,
  stage: 'staging',
  companyId: 'krc4PsvgVuBKYck4mxen',
  firebaseConfig: {
    apiKey: "AIzaSyCLMXshlqSvURQyJUn4kOksndIKOtnsXLk",
    authDomain: "eule-staging.firebaseapp.com",
    projectId: "eule-staging",
    storageBucket: "eule-staging.appspot.com",
    messagingSenderId: "1060778636170",
    appId: "1:1060778636170:web:e77afc3b21ce237e9d8e73",
    measurementId: "G-PHXHJ597XF"
  }
};
