@if (isLoading$ | async) {
<mat-spinner class="center"></mat-spinner>
} @else {
<div mat-dialog-title class="dialog-title flexbox align-start">
  <div class="flex-grow-1">
    @if (!data.id) { Neue Aufgabe } @else {
    <div class="title-text-wrapper flexbox direction-column">
      <div class="upper-title primary">Aufgabe</div>
      <div class="title-text flexbox align-center small-gap">
        <div class="strong task-number">{{ currentTask?.taskNumber || '' }}.</div>
        <div class="strong task-title">{{ currentTask?.title || '' }}</div>
      </div>
    </div>
    }
  </div>
  <div class="flex-grow-1 flexbox justify-end">
    <button mat-icon-button class="close-button smaller-button" [matTooltip]="'Dialog schließen'"
      (click)="dialogRef.close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content class="overflow-hidden flexbox direction-column w-100-percent content" id="dialog-content">
  <div id="indicator-task-dialog-container" class="custom-scrollbar w-100-percent">
    <div [formGroup]="taskForm">
      <div class="upper-form-content flexbox align-start w-100-percent">
        <div class="left-form-content flexbox direction-column smaller-gap">
          <div class="general-data">
            <div class="label default-border-color primary-text">Allgemein</div>
            <div class="row">
              <mat-form-field class="flex-grow-1 title-control">
                <mat-label>Titel</mat-label>
                <input matInput formControlName="title" />
              </mat-form-field>

              <mat-form-field class="w-100-percent smaller-font discipline-control">
                <mat-label>Disziplin</mat-label>
                <mat-select formControlName="discipline">
                  @for (discipline of disciplineEnum | keyvalue; track discipline) {
                  <mat-option [value]="discipline.key">{{ discipline.value }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>

            <div class="row">
              <mat-form-field class="w-100-percent smaller-font">
                <mat-label>Typ</mat-label>
                <mat-select formControlName="type">
                  @for (type of typeArray | keyvalue; track type) {
                  <mat-option [value]="type.key">{{ type.value }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>

              <mat-form-field class="w-100-percent smaller-font">
                <mat-label>Status</mat-label>
                <mat-select formControlName="status">
                  @for (status of statusArray | keyvalue; track status) {
                  <mat-option [value]="status.key">{{ status.value }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>

              <mat-form-field class="w-100-percent smaller-font">
                <mat-label>Lph</mat-label>
                <mat-select formControlName="leistungsPhasen" multiple>
                  <mat-select-trigger>
                    <span class="example-additional-selection"> {{ taskForm.get('leistungsPhasen')?.value }} </span>
                  </mat-select-trigger>
                  @for (lph of leistungsPhasenList; track lph) {
                  <mat-option [value]="lph">Lph {{ lph }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>

            <div class="row">
              <mat-form-field class="w-100-percent smaller-font">
                <mat-label>Verantwortlich</mat-label>
                <mat-select formControlName="responsibleId">
                  @for (responsible of responsibleArray; track responsible) {
                  <mat-option [value]="responsible.id">{{ responsible?.firstName }} {{ responsible?.lastName }}
                  </mat-option>
                  }
                </mat-select>
              </mat-form-field>

              <mat-form-field class="w-100-percent smaller-font">
                <mat-label>Ersteller</mat-label>
                <mat-select formControlName="creatorId">
                  @for (responsible of responsibleArray; track responsible) {
                  <mat-option [value]="responsible.id">{{ responsible.firstName }} {{ responsible.lastName }}
                  </mat-option>
                  }
                </mat-select>
              </mat-form-field>

              <mat-form-field class="w-100-percent smaller-font">
                <mat-label>Priorität</mat-label>
                <mat-select formControlName="priority">
                  @for (priority of priorityArray | keyvalue; track priority) {
                  <mat-option [value]="priority.key">{{ priority.value }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          @if ((taskInitialized$ |async) && currentTask) {
          <div class="indicator-references">
            <div class="label default-border-color primary-text">Verknüpfte Indikatoren</div>
            <eule-task-indicator-list [taskIndicatorReferences]="currentTask.indicatorReferences || []" />
          </div>
          }

          <div class="attachments flexbox direction-column">
            <div class="label default-border-color primary-text flexbox small-gap align-center">
              <div class="attachments-title">Anhänge</div>
              <div [matTooltip]="'Aufgabe zu Indikator hinzufügen'">
                <button mat-mini-fab color="accent" class="no-shadow smaller-button"
                  (click)="onOpenAddAttachmentDialog()">
                  <mat-icon>add</mat-icon>
                </button>
              </div>
            </div>
            <div class="flexbox align-start semi-small-gap">
              @for (attachment of taskAttachments; track attachment) { @if (attachment.id) {
              <eule-document-tile [documentId]="attachment.id" [temporary]="attachment.temporary" class="doc-tile" />
              } }
            </div>
          </div>
        </div>
        <div class="right-form-content flexbox direction-column smaller-gap">
          <div class="description flex-grow-1">
            <div class="label default-border-color primary-text">Erläuterung</div>
            <mat-form-field class="w-100-percent">
              <mat-label>Beschreibung</mat-label>
              <textarea matInput formControlName="description" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="6" cdkAutosizeMaxRows="7"></textarea>
            </mat-form-field>
          </div>

          <div class="comments">
            <div class="label default-border-color primary-text">Kommentare</div>
            <div class="comments flexbox direction-column">
              @for (commentControlKey of getAllFormGroupControlsKeys(commentsForm.controls); track commentControlKey) {
              <div [formGroup]="commentsForm" class="row">
                <div class="col flexbox flex-grow-1">
                  <div [formGroup]="getCommentControlGroup(commentControlKey)">
                    <div class="w-100-percent flexbox direction-column">
                      <div class="action-buttons flexbox xs-gap justify-end">
                        <button mat-mini-fab color="warn" class="delete-comment no-shadow small-button"
                          matTooltip="Kommentar löschen" (click)="deleteComment(commentsForm.get(commentControlKey))">
                          <mat-icon>delete</mat-icon>
                        </button>
                        @if (commentsForm.get(commentControlKey)!.disabled) {
                        <button mat-mini-fab color="accent" class="edit-comment no-shadow small-button"
                          matTooltip="Kommentar bearbeiten"
                          (click)="toggleEditComment(commentsForm.get(commentControlKey))">
                          <mat-icon>edit</mat-icon>
                        </button>
                        } @if (commentsForm.get(commentControlKey)!.enabled) {
                        <button mat-mini-fab color="primary" class="no-shadow small-button save-comment"
                          (click)="saveComment(commentsForm.get(commentControlKey))">
                          <mat-icon>save</mat-icon>
                        </button>
                        }
                      </div>
                      <mat-form-field class="mat-form-field-long w-100-percent">
                        <mat-label>Kommentar editieren</mat-label>
                        <textarea matInput formControlName="content" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                          cdkAutosizeMinRows="6" cdkAutosizeMaxRows="7"></textarea>
                      </mat-form-field>
                      <div class="flexbox justify-end align-center secondary-text comment-creator">
                        {{ getUserName(getCommentCreatorId(commentControlKey)) }}
                        {{ getCommentLastUpdatedDate(commentControlKey) | date : 'dd.MM.yyyy' }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              }
            </div>

            <div class="row">
              <div class="col flex-grow-1">
                <mat-form-field class="mat-form-field-long w-100-percent">
                  <mat-label>Neuer Kommentar</mat-label>
                  <textarea matInput formControlName="newCommentControl" cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="6" cdkAutosizeMaxRows="7"></textarea>
                </mat-form-field>
                <div>
                  <button mat-flat-button [disabled]="!taskForm.get('newCommentControl')?.value" color="primary"
                    (click)="addComment()">
                    Kommentar hinzufügen
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lower-form-content w-100-percent"></div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions id="dialog-actions">
  @if (isUpdating$ | async) {
  <div class="flexbox align-center small-gap loading-indicator">
    <mat-spinner diameter="25" />
    <div class="secondary-text spinner-text">Die Aufgabe wird gespeichert</div>
  </div>
  } @else {
  <button mat-stroked-button (click)="closeDialog(false)" cdkFocusInitial>@if (data.id) { Schließen } @else { Abbrechen
    }</button>
  <button mat-flat-button color="primary" [disabled]="taskForm.invalid || !formDirty" (click)="closeDialog(true)">
    <span class="button-label"> Speichern </span>
  </button>
  }
</mat-dialog-actions>
}