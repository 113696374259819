export enum DisciplineEnum {
  ARCHITECTURE = 'Architektur',
  HLSK = 'HLSK',
  ELT = 'ELT',
  SUPPORT_STRUCTURE = 'Tragwerk',
  LANDSCAPE = 'Landschaft',
  BUILDING_PHYSICS = 'Bauphysik',
  AUDITOR = 'Auditor',
  BUILDING_OWNER = 'Bauherr',
  CONSTRUCTION_WORK = 'Bauausführung',
  OPEN = 'offen',
}
